<template>
  <!-- Base Carousel -->
  <div class="relative flex items-center" ref="assetContainer">
    <!-- Go Left -->
    <transition>
      <button v-if="selectedCardIndex > 0" class="carousel-nav nav-left"
      @click="() => {setCarouselIndex(selectedCardIndex - 1)}">
        <CarouselChevronIcon class="nav-chevron" />
      </button>
    </transition>
    <div class="w-full">
      <!-- Image -->
      <div v-if="getSelectedCard.type !== 'video'" class="relative w-full" 
      :class="{'rounded-lg overflow-hidden': rounded}">
        <transition name="spinner">
          <div v-if="loadingAsset" class="absolute top-0 left-0 right-0 z-10 flex items-center justify-center"
          :style="{height: assetHeight ? `${assetHeight}px` : '100%'}">
            <BaseLoadingSpinner />
          </div>
        </transition>
        <img :src="getSelectedCard.image" alt="Image" 
        class="w-full object-contain"
        :style="{height: assetHeight ? `${assetHeight}px` : 'auto'}"
        @load="onAssetLoaded">
      </div>
      <!-- Video -->
      <div v-if="getSelectedCard.type === 'video'" class="w-full" 
      :class="{'rounded-lg overflow-hidden': rounded}">
        <video :src="getSelectedCard.video" controls 
        ref="carouselVideoPlayer"
        class="w-full object-contain"
        :style="{height: assetHeight ? `${assetHeight}px` : 'auto'}"
        @loadedmetadata="onAssetLoaded"/>
      </div>
    </div>
    <!-- Go Right -->
    <transition>
      <button v-if="selectedCardIndex < items.length - 1" class="carousel-nav nav-right"
      @click="() => {setCarouselIndex(selectedCardIndex + 1)}">
        <CarouselChevronIcon class="nav-chevron" />
      </button>
    </transition>
  </div>
</template>

<script>
import CarouselChevronIcon from './Icons/CarouselChevronIcon.vue'

export default {
  name: 'BaseCarousel',
  components: {
    CarouselChevronIcon
  },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    rounded: {
      type: Boolean,
      default: false
    },
    adjustAssetHeightOnChange: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      selectedCardIndex: 0,
      assetHeight: null,
      loadingAsset: false,
      firstAssetLoaded: false
    }
  },
  computed: {
    getSelectedCard () {
      return this.items[this.selectedCardIndex]
    }
  },
  methods: {
    onAssetLoaded (event) {
      this.loadingAsset = false
      if (!this.firstAssetLoaded) {
        this.firstAssetLoaded = true
        this.$emit('assetResized')
        return
      }
      this.$nextTick(() => {
        if (this.adjustAssetHeightOnChange) {
          const elmRef = event.target
          const containerWidth = this.$refs.assetContainer.getBoundingClientRect().width
          if (elmRef.tagName === 'IMG') {
            const aspectRatio = elmRef.naturalHeight / elmRef.naturalWidth
            this.assetHeight = containerWidth * aspectRatio
          } else if (elmRef.tagName === 'VIDEO') {
            const aspectRatio = elmRef.videoHeight / elmRef.videoWidth
            this.assetHeight = containerWidth * aspectRatio
          }
          this.$emit('assetResized')
        } else {
          this.assetHeight = event.target.getBoundingClientRect().height
        }
      })
    },
    setCarouselIndex (index) {
      // This func is called directly on the component instance from a parent
      this.assetHeight = this.$refs.assetContainer.getBoundingClientRect().height
      if (index !== this.selectedCardIndex) this.loadingAsset = true
      this.selectedCardIndex = index
      this.$emit('update:carousel-index', this.selectedCardIndex)
    },
    playVideoAtTimestamp (time) {
      // This func is called directly on the component instance from a parent
      const videoPlayer = this.$refs.carouselVideoPlayer
      videoPlayer.currentTime = time
      if (videoPlayer.paused) videoPlayer.play()
    }
  }
}
</script>

<style scoped lang="css">
.carousel-nav {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  width: 26px;
  padding: 4px;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(18px);
  box-shadow: 0px 3px 3px -1.5px rgba(0, 0, 0, 0.12);
  transition: 
    width 150ms ease-in-out,
    background-color 150ms ease-in-out,
    backdrop-filter 150ms ease-in-out;
}
.carousel-nav:hover {
  width: 30px;
  background-color: rgba(0, 0, 0, 0.68);
  backdrop-filter: blur(26px);
}
.carousel-nav.nav-right {
  right: -4px;
  justify-content: flex-start;
  border-radius: 6px 0px 0px 6px;
}
.carousel-nav.nav-left {
  left: -4px;
  justify-content: flex-end;
  border-radius: 0px 6px 6px 0px;
}
.carousel-nav .nav-chevron {
  color: rgba(255, 255, 255, 0.85);
  flex-shrink: 0;
  transition: color 150ms ease-in-out;
}
.carousel-nav:hover .nav-chevron {
  color: rgba(255, 255, 255, 1);
}
.carousel-nav.nav-right .nav-chevron {
  transform: rotate(180deg);
}

.v-leave-active {
  cursor: default;
  transition: opacity 150ms ease-in-out;
  width: 30px;
}
.v-leave-from {
  opacity: 1;
}
.v-leave-to {
  opacity: 0;
}

.spinner-enter-active {
  transition: opacity 75ms ease-in-out;
  transition-delay: 150ms;
}
.spinner-leave-active {
  transition: opacity 75ms ease-in-out;
}
.spinner-enter-from, .spinner-enter, .spinner-leave-to {
  opacity: 0;
}
.spinner-enter-to, .spinner-leave-from {
  opacity: 1;
}
</style>