import { render, staticRenderFns } from "./BaseDrawer.vue?vue&type=template&id=bc3f0208&scoped=true"
import script from "./BaseDrawer.vue?vue&type=script&lang=js"
export * from "./BaseDrawer.vue?vue&type=script&lang=js"
import style0 from "./BaseDrawer.vue?vue&type=style&index=0&id=bc3f0208&prod&scoped=true&lang=sass"
import style1 from "./BaseDrawer.vue?vue&type=style&index=1&id=bc3f0208&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bc3f0208",
  null
  
)

export default component.exports